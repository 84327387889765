import { Ref } from 'vue';
import { Response } from '@/interface/api/common';
import { signReleaseSlave } from '@/service/proRequisition';
import { ISignReleaseSlaveParams } from '@/service/proRequisition/type';

export default function userList(
  params: Ref<ISignReleaseSlaveParams>
): [() => Promise<Response>] {
  const signReleaseSlaveAsync = async () => {
    const res = await signReleaseSlave(params.value);
    return res;
  };

  return [signReleaseSlaveAsync];
}
