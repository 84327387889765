import { hyRequest4 } from '@/service';
import {
  IConsumptionInfo,
  ILogInfo,
  ILogParams,
  IRechargeInfo,
  ITimeRange
} from './type';

const factory_id = 358; // 蓝天厂区id
// 数据总览
export function getAllData(params: ITimeRange) {
  return hyRequest4.post<any>({
    url: '/ygBoss/statistics/dataByDate',
    data: {
      factory_id,
      ...params
    }
  });
}

// 充值统计
export function rechargeCount(params: ITimeRange) {
  return hyRequest4.post<IRechargeInfo>({
    url: '/ygBoss/statistics/chzByDate',
    data: {
      factory_id,
      ...params
    }
  });
}

// 充值方式列表
export function rechargeTypeList(params: ITimeRange) {
  return hyRequest4.post<any>({
    url: '/ygBoss/statistics/chzByDateTwo',
    data: {
      factory_id,
      ...params
    }
  });
}

// 消费统计
export function consumptionCount(params: ITimeRange) {
  return hyRequest4.post<IConsumptionInfo>({
    url: '/ygBoss/statistics/saleByDate',
    data: {
      factory_id,
      ...params
    }
  });
}

// 充值记录
export function rechargeLog(data: ILogParams) {
  return hyRequest4.post<ILogInfo[]>({
    url: '/ygBoss/statistics/chzSortByDate',
    data: {
      ...data,
      factory_id
    }
  });
}

// 消费记录
export function consumptionLog(data: ILogParams) {
  return hyRequest4.post<ILogInfo[]>({
    url: 'ygBoss/statistics/saleSortByDate',
    data: {
      ...data,
      factory_id
    }
  });
}

// 新增客户数据
export function customerCount(params: ITimeRange) {
  return hyRequest4.post<any>({
    url: '/ygBoss/statistics/cusByDate',
    data: {
      factory_id,
      ...params
    }
  });
}
