/* unplugin-vue-components disabled */import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  name: 'ReleaseItem',
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    oaToken: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var statusFormat = function statusFormat(status) {
      // 出库审核状态：0未审核 1审核中  2 待出库 3已驳回  4已出库
      var text = '';
      switch (status) {
        case 0:
          text = '未审核';
          break;
        case 1:
          text = '审核中';
          break;
        case 2:
          text = '待出库';
          break;
        case 3:
          text = '已驳回';
          break;
        case 4:
          text = '已出库';
          break;
      }
      return text;
    };
    var router = useRouter();
    var toMaterialList = function toMaterialList() {
      router.push({
        name: 'RequisitionMaterialList',
        params: {
          release_no: props.item.release_no
        }
      });
    };
    var openLink = function openLink(requestid) {
      var timestamp = new Date().getTime();
      window.location.href = "http://oa.zydltec.com/spa/workflow/static4mobileform/index.html?ssoToken=".concat(props.oaToken, "&_random=").concat(timestamp, "#/req?requestid=").concat(requestid, "&timestamp=").concat(timestamp);
    };
    return {
      statusFormat: statusFormat,
      toMaterialList: toMaterialList,
      openLink: openLink
    };
  }
});