import type { Ref } from 'vue';
import { Response } from '@/interface/api/common';
import { execRelease } from '@/service/proRequisition';
import { IExecReleaseParams } from '@/service/proRequisition/type';

export default function userList(
  params: Ref<IExecReleaseParams>
): [() => Promise<Response>] {
  const execReleaseAsync = async () => {
    const data = await execRelease(params.value);
    return data;
  };

  return [execReleaseAsync];
}
