import hyRequest from '@/service';
enum DashboardApi {
  addFinanceBudget = '/info/addBudgetAmt',
  getDeptList = '/info/qryUserOrg'
}

export function addFinanceBudget(data: { budgetAmt: string; remark: string }) {
  return hyRequest.post<any>({
    url: `${DashboardApi.addFinanceBudget}`,
    data
  });
}

export function getDeptList() {
  return hyRequest.post<any>({
    url: `${DashboardApi.getDeptList}`
  });
}
