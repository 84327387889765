/* unplugin-vue-components disabled */export default defineComponent({
  name: 'MaterialItem',
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    return {};
  }
});