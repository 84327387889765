/* unplugin-vue-components disabled */import _objectSpread from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.splice.js";
import useList from './hooks/useList';
import emitter from '@/eventbus/index';
export default defineComponent({
  name: 'SelectWarehouse',
  components: {},
  setup: function setup() {
    var onSearch = function onSearch() {
      //页码重置为1 list清空
      params.value.page = 1;
      list.value.splice(0);
      loading.value = true;
      finished.value = false;
      getWarehouseListAsync();
    };
    var params = ref({
      page: 1,
      limit: 10,
      warehouse_name: ''
    });
    var _useList = useList(params),
      _useList2 = _slicedToArray(_useList, 5),
      list = _useList2[0],
      loading = _useList2[1],
      finished = _useList2[2],
      getWarehouseListAsync = _useList2[3],
      onLoad = _useList2[4];
    var selectWarehouse = ref();
    var router = useRouter();
    var toggle = function toggle(item) {
      selectWarehouse.value = item;
      emitter.emit('select-warehouse', _objectSpread({}, selectWarehouse.value));
      router.back();
    };
    onMounted(function () {
      getWarehouseListAsync();
    });
    return {
      params: params,
      onSearch: onSearch,
      list: list,
      toggle: toggle,
      loading: loading,
      finished: finished,
      onLoad: onLoad
    };
  }
});