import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "CollectionItemTypePopup"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_picker = _resolveComponent("van-picker");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: _ctx.show,
    "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.show = $event;
    }),
    position: "bottom"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_picker, {
        title: "选择领用物品类型",
        columns: _ctx.type,
        round: "",
        onConfirm: _ctx.onConfirm,
        onCancel: _ctx.onCancel
      }, null, 8, ["columns", "onConfirm", "onCancel"])];
    }),
    _: 1
  }, 8, ["show"])]);
}