/* unplugin-vue-components disabled */import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { DAYJS_FORMAT } from '@/config/common';
export default defineComponent({
  name: 'RankListItem',
  props: {
    type: {
      type: String,
      default: 'aggregate' //'aggregate' | 'mortar' | 'shangtong'
    },
    item: {
      type: Object,
      required: true
    },
    typeList: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var unitFormat = function unitFormat(val) {
      var text = '';
      if (val === 'aggregate' || val === 'mortar') {
        text = '吨';
      }
      if (val === 'shangtong') {
        text = '方';
      }
      return text;
    };
    var timeFormat = function timeFormat(val) {
      return dayjs(val).format(DAYJS_FORMAT.YYYYMMDD_HHMMSS);
    };
    var payTypeFormat = function payTypeFormat(type) {
      var data = props.typeList.find(function (item) {
        return item.pay_type === type;
      });
      if (data) {
        return data.pay;
      }
      return '';
    };
    return {
      unitFormat: unitFormat,
      timeFormat: timeFormat,
      payTypeFormat: payTypeFormat
    };
  }
});