import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7d7fece8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "rankListItem"
};
var _hoisted_2 = {
  class: "flex info-box"
};
var _hoisted_3 = {
  class: "info flex-1"
};
var _hoisted_4 = {
  class: "weight"
};
var _hoisted_5 = {
  class: "brief"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.customer_name), 1), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.money), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_ctx.timeFormat(_ctx.item.create_time)), 1), _createElementVNode("span", null, _toDisplayString(_ctx.payTypeFormat(_ctx.item.pay_type)), 1)])]);
}