import hyRequest, { hyRequest1 } from '@/service';
import {
  IDictsItem,
  IListData,
  IWarehouseListParams,
  IMaterialListParams,
  IWarehouseItem,
  IApplyParams,
  IReleaseListParams,
  IReleaseInfoParams,
  IArchivesInfoParams,
  IExecReleaseParams,
  IConfirmReleaseParams,
  ISignReleaseSlaveParams
} from './type';

enum DashboardApi {
  getDicts = '/terminal/terminalInfo/getDicts',
  getUserList = '/manager/warehouse/userList',
  getWarehouseList = '/manager/warehouse/warehouseList',
  getStoneList = '/manager/warehouse/stoneList',
  releaseApply = '/manager/warehouse/releaseApply',
  getReleaseList = '/manager/warehouse/releaseList',
  getUserInfo = '/appUser/getUserInfo',
  getReleaseInfo = '/smart/warehouse/releaseInfoManager',
  getArchivesInfo = '/smart/warehouse/archivesInfo',
  execRelease = '/smart/warehouse/execRelease',
  confirmRelease = '/smart/warehouse/confirmReleaseNew',
  signReleaseSlave = '/smart/warehouse/signReleaseSlave',
  getOAToken = '/oa/getOAToken',
  getDepartmentList = '/smart/warehouse/departmentList',
  getDepartmentInfo = '/smart/warehouse/departmentInfo'
}

//领用物品类型
export function getDicts() {
  return hyRequest1.get<IDictsItem[]>({
    url: `${DashboardApi.getDicts}?name=oa_type`
  });
}

//领用员工
export function getUserList(params: {
  page: number;
  limit: number;
  username?: string;
}) {
  return hyRequest1.get<any>({
    url: `${DashboardApi.getUserList}`,
    params
  });
}
//选择仓库
export function getWarehouseList(params: IWarehouseListParams) {
  return hyRequest1.get<IListData<IWarehouseItem>>({
    url: `${DashboardApi.getWarehouseList}`,
    params
  });
}

//选择物料
export function getStoneList(params: IMaterialListParams) {
  return hyRequest1.get<IListData>({
    url: `${DashboardApi.getStoneList}`,
    params
  });
}

//领用提交
export function releaseApply(params: IApplyParams) {
  return hyRequest1.post<any>({
    url: `${DashboardApi.releaseApply}`,
    data: params
  });
}

//出库单列表
export function getReleaseList(params: IReleaseListParams) {
  return hyRequest1.get<IListData>({
    url: `${DashboardApi.getReleaseList}`,
    params
  });
}

//获取当前用户信息
export function getUserInfo() {
  return hyRequest.get<any>({
    url: `${DashboardApi.getUserInfo}`
  });
}

//获取出库单详情
export function getReleaseInfo(params: IReleaseInfoParams) {
  return hyRequest1.get<any>({
    url: `${DashboardApi.getReleaseInfo}`,
    params
  });
}

//获取领用物料的详情
export function getArchivesInfo(params: IArchivesInfoParams) {
  return hyRequest1.get<any>({
    url: `${DashboardApi.getArchivesInfo}`,
    params
  });
}
//确定领用物料
export function execRelease(params: IExecReleaseParams) {
  return hyRequest1.post<any>({
    url: `${DashboardApi.execRelease}`,
    data: params
  });
}

//标记领用物料已领未领
export function signReleaseSlave(params: ISignReleaseSlaveParams) {
  return hyRequest1.post<any>({
    url: `${DashboardApi.signReleaseSlave}`,
    data: params
  });
}
//确定出库
export function confirmRelease(params: IConfirmReleaseParams) {
  return hyRequest1.post<any>({
    url: `${DashboardApi.confirmRelease}`,
    data: params
  });
}

//获取oatoken
export function getOAToken(data: { loginid: string }) {
  return hyRequest.post<any>({
    url: `${DashboardApi.getOAToken}`,
    data
  });
}

//第一层列表
export function getDepartmentList() {
  return hyRequest1.get<any>({
    url: `${DashboardApi.getDepartmentList}`
  });
}

//部门信息 参数id 部门id
export function getDepartmentInfo(params: { id: number; user_name: string }) {
  return hyRequest1.get<any>({
    url: `${DashboardApi.getDepartmentInfo}`,
    params
  });
}
