import type { Ref } from 'vue';
import { Response } from '@/interface/api/common';
import { confirmRelease } from '@/service/proRequisition';
import { IConfirmReleaseParams } from '@/service/proRequisition/type';

export default function userList(
  params: Ref<IConfirmReleaseParams>
): [() => Promise<Response>] {
  const confirmReleaseAsync = async () => {
    const res = await confirmRelease(params.value);
    return res;
  };

  return [confirmReleaseAsync];
}
